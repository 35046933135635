<template>
	<div class="system-content" :style="height">
		<div class="top-box">
			<img class="img-icon" src="@/assets/images/xuzhi.png" alt="">
			<span class="title">考前须知</span>
			<button class="back-btn" @click="back">返回</button>
		</div>
		<p class="text">{{infoData.remark}}</p>
		<ul class="middle-box">
			<li>
				<p>开始时间：</p>
				<p>{{infoData.startTime}}至{{infoData.endTime}}</p>
			</li>
			<li>
				<p>考试时间：</p>
				<p>{{infoData.examinTime}}分钟</p>
			</li>
			<li>
				<p>最多次数：</p>
				<p>{{infoData.examinLimiteTimes}}</p>
			</li>
			<li>
				<p>剩余次数：</p>
				<p>{{infoData.laveExaminLimiteTimes}}</p>
			</li>
		</ul>
		<p class="tips">提示：考试过程中不可断网</p>
		<button class="submit" @click="handleExam">开始考试</button>
		<p>
			<img class="img-icon" src="@/assets/images/jilu.png" alt="">
			<span class="title">考试记录</span>
		</p>
		<img class="exam-bg" v-if="infoData.resultList.length == 0" src="@/assets/images/exam-bg.png" alt="">
		<div class="table-box" v-if="infoData.resultList.length > 0">
			<table class="exam-table" cellspacing="0" cellpadding="0">
				<thead>
					<tr>
						<th>开始时间</th>
						<th>交卷时间</th>
						<th>用时</th>
						<th>成绩(百分制)</th>
						<th>状态</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(td,i) in infoData.resultList" :key="i">
						<td>{{td.startTime}}</td>
						<td>{{td.endTime}}</td>
						<td>{{td.answerTime}}</td>
						<td>{{td.userScore}}</td>
						<td>
							<span v-if="td.passFlag == 1">进行中</span>
							<span v-if="td.passFlag == 2">未通过</span>
							<span v-if="td.passFlag == 3">通过</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			height: '',
			backFlag: true,
			infoData: {
				resultList: []
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.backFlag = this.$route.query.backBtn == 1 ? false : true;
			this.height = 'min-height:' + (window.innerHeight - 160) + 'px;';
			let that = this;
			that.$request.post(
				"getExamBeforeInfo",
				true,
				{
					trainId: that.$route.query.id
				},
				function (r) {
					if (r.code == "0") {
						that.infoData = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		back() {
			if(this.backFlag) {
				this.$router.go(-1);
			}else {
				this.$router.push({
					name: 'onlineExam'
				})
			}
		},
		handleExam() {
			if(this.infoData.laveExaminLimiteTimes == 0) {
				this.$message.error('可考试次数为零');
				return
			}
			let that = this;
			that.$request.post(
				"startExam",
				true,
				{
					trainId: that.$route.query.id
				},
				function (r) {
					if (r.code == "0") {
						window.sessionStorage.setItem('examTime',new Date().getTime()+that.infoData.examinTime*60*1000);
						window.sessionStorage.setItem('examTimeStr',that.infoData.examinTime*60);
						that.$router.push({
							name: 'exameIn',
							query: {
								id: that.$route.query.id,
								examId: r.data.id,
								courseName: that.infoData.courseName,
								courseId: that.$route.query.courseId
							}
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	box-sizing: border-box;
	margin-top: 20px;
	background-color: #fff;
	border-radius: 10px;
	padding: 15px;
	.title{
			width: 79px;
			height: 20px;
			font-size: 20px;
			font-weight: 400;
			color: #3F3F3F;
			line-height: 19px;
		}
	.top-box{
		margin-bottom: 20px;
		.back-btn{
			background-color: rgba(233, 81, 94, 0.2);
			color: rgba(233, 81, 94, 1);
			width: 71px;
			height: 27px;
			border-radius: 3px;
			border: none;
			outline: none;
			float: right;
			cursor: pointer;
		}
	}
	.text{
		margin-bottom: 10px;
		width: 90%;
		font-size: 15px;
		font-weight: 500;
		color: #333333;
		line-height: 21px;
	}
	.middle-box{
		display: flex;
		li{
			list-style: none;
			flex: 1;
			p{
				margin-bottom: 5px;
			}
		}
	}
	.tips{
		color: rgba(233, 81, 94, 1);
		margin: 10px 0;
	}
	.submit{
		display: block;
		width: 134px;
		height: 27px;
		background: #117FFC;
		border-radius: 3px;
		color: #fff;
		border: none;
		outline: none;
		cursor: pointer;
		margin: 0 auto;
	}
}
.img-icon{
	width: 20px;
    vertical-align: bottom;
    margin-right: 5px;
}
.table-box,
.exam-bg{
	width: 100%;
	margin-top: 20px;
	box-sizing: border-box;
	padding: 0 10px;
}
.exam-table{
	width: 100%;
	border-collapse:separate;
	thead{
		tr{
			th{
				line-height: 36px;
				text-align: center;
				background-color: rgba(249, 250, 253, 1);
				border-left: 1px solid #ddd;
				border-top: 1px solid #ddd;
				&:first-child{
					border-top-left-radius: 5px;
				}
				&:last-child{
					border-top-right-radius: 5px;
					border-right: 1px solid #ddd;
				}
			}
		}
	}
	tbody{
		tr{
			td{
				line-height: 32px;
				color: #555;
				text-align: center;
				border-left: 1px solid #ddd;
				border-top: 1px solid #ddd;
				&:last-child{
					border-right: 1px solid #ddd;
				}
			}
			&:last-child{
				td{
					border-bottom: 1px solid #ddd;
				}
			}
		}
	}
}
</style>
